@charset "UTF-8";
table {
    border-collapse: collapse;
    border-spacing: 0;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
    content: '';
    content: none;
}
*,
:after,
:before {
    box-sizing: border-box;
}

@font-face {
    font-family: Chevin;
    src: url(../fonts/chevin-thin.woff2) format('woff2'), url(../fonts/chevin-thin.woff) format('woff');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: Chevin;
    src: url(../fonts/chevin-light.woff2) format('woff2'), url(../fonts/chevin-light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: Chevin;
    src: url(../fonts/chevin-medium.woff2) format('woff2'), url(../fonts/chevin-medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: Chevin;
    src: url(../fonts/chevin-demibold.woff2) format('woff2'), url(../fonts/chevin-demibold.woff) format('woff');
    font-weight: 600;
    font-style: normal;
}

html {
    font-size: 62.5%;
}
body {
    font-family: Chevin, sans-serif, sans-serif;
    font-weight: 300;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.25;
    color: #323232;
}
button,
input,
optgroup,
option,
select,
textarea {
    font-family: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Chevin, sans-serif, sans-serif;
    color: #323232;
    font-weight: 300;
}

h1 {
    font-weight: 100;
    font-size: 3.8rem;
    line-height: 4rem;
}
@media screen and (max-width: 767px) {
    h1 {
        font-weight: 300;
        font-size: 28px;
        font-size: 2.8rem;
        line-height: 30px;
        line-height: 3rem;
    }
}
h2 {
    font-size: 2.8rem;
    line-height: 3rem;
}
h3 {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3rem;
}
h4 {
    font-size: 2.2rem;
    line-height: 2rem;
}
h5 {
    font-size: 1.8rem;
    line-height: 2rem;
}
h6 {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
}
p {
    line-height: 1.5;
}
b,
strong {
    font-weight: 600;
}
a {
    text-decoration: none;
    color: #323232;
}
a:focus {
    outline: 0;
}
