@import './opux.css';

canvas.a-canvas.art-move-cursor:hover {
    cursor: url(../img/move.svg) 16 16, auto;
}
canvas.a-canvas.art-enlarge-cursor:hover {
    cursor: url(../img/enlarge.svg) 16 16, auto;
}

.a-enter-vr-button {
    right: auto;
    left: 40px;
}

.popup {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (min-width: 800px) {
    #intro {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;
    }
}
.popup {
    box-sizing: border-box;
    position: fixed;
    background-color: #fff;
    z-index: 10000; /* enter vr button z-index is 9999 */
    padding: 50px;
    overflow-y: auto;
}
.popup.hidden {
    display: none;
}
.popup p {
    margin-bottom: 20px;
}
.popup .close {
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent url(../img/close.svg) no-repeat 0 0;
    background-size: contain;
}
.popup button {
    display: block;
    cursor: pointer;
    padding: 9px 18px;
    font: 300 16px/20px Chevin, sans-serif;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    height: auto;
    vertical-align: top;
    border-radius: 20px;
    color: #323232;
    min-width: 15rem;
    background: #ff6a10;
    color: #fff;
    border: 1px solid transparent;
    margin: 0 auto;
}

.visible-on-mobile,
.visible-on-desktop,
.visible-on-vr {
    display: none;
}
.is-desktop .visible-on-desktop,
.is-mobile .visible-on-mobile,
.is-vr .visible-on-vr {
    display: block;
}

#info h1 {
    padding: 0;
    margin: 0;
}
#info h4 {
    margin-bottom: 20px;
}

#info_img {
    float: left;
    margin: 0 20px 0 0;
    max-width: 50%;
    max-height: 80vh;
    width: auto;
}

#info_ar {
    display: none;
}
.ar-ql #info_ar.ar {
    display: inline-block;
    color: #ff6a10;
    margin-bottom: 10px;
}

#info footer {
    text-align: center;
    margin-top: 20px;
}

@media (max-width: 640px) {
    #info {
        padding: 50px 20px;
    }
    #info_img {
        float: none;
        margin: 20px 0 20px 0;
        max-width: 100%;
    }
}
